<template lang="pug">
.edit-profile
  AppOverlayLoader(:state="loading")
  BAlert.create-account-alert(
    :show="isAccountUpdatedAlertShown"
    variant="success"
    dismissible
    @dismissed="isAccountUpdatedAlertShown = false"
  ) {{ $t("account_management.create_account.account_updated") }}

  .form
    .form-row(
      :data-cy="$t('account_management.create_account.fields.last_name')"
    )
      .form-row-label
        FormFieldLabel(:title="$t('account_management.create_account.fields.last_name')")
      .form-row-field
        BFormInput(
          type="text"
          name="last-name"
          v-model="account.last_name"
          :placeholder="$t('account_management.create_account.placeholders.last_name')"
          :state="getFieldState('last_name')"
        )
        BFormInvalidFeedback(v-if="$v.account.last_name.$error") {{ $t("validations.at_least_1_letter") }}

    .form-row(
      :data-cy="$t('account_management.create_account.fields.first_name')"
    )
      .form-row-label
        FormFieldLabel(:title="$t('account_management.create_account.fields.first_name')")
      .form-row-field
        BFormInput(
          type="text"
          name="first-name"
          v-model="account.first_name"
          :placeholder="$t('account_management.create_account.placeholders.first_name')"
          :state="getFieldState('first_name')"
        )
        BFormInvalidFeedback(v-if="$v.account.first_name.$error") {{ $t("validations.at_least_1_letter") }}

    .form-row(
      :data-cy="$t('account_management.create_account.fields.password')"
    )
      .form-row-label
        FormFieldLabel(:title="$t('account_management.create_account.fields.password')")
      .form-row-field
        AppInputPassword(
          v-model="account.password"
          :placeholder="$t('account_management.create_account.placeholders.password')"
          :invalid="isInvalidAccountPassword"
        )

    .form-row(
      :data-cy="$t('account_management.create_account.fields.password_confirmation')"
    )
      .form-row-label
        FormFieldLabel(:title="$t('account_management.create_account.fields.password_confirmation')")
      .form-row-field
        AppInputPassword(
          v-model="account.password_confirmation"
          name="password-confirmation"
          :placeholder="$t('account_management.create_account.placeholders.password_confirmation')"
          :invalid="isInvalidAccountPasswordConfirmation"
        )
        .password-errors(v-if="isInvalidAccountPassword || isInvalidAccountPasswordConfirmation")
          li(v-if="minLengthPasswordError") {{ minLengthPasswordError }}
          li(v-if="equalConfirmationPasswordError") {{ equalConfirmationPasswordError }}

    .form-row(
      :data-cy="$t('account_management.create_account.fields.email')"
    )
      .form-row-label
        FormFieldLabel(:title="$t('account_management.create_account.fields.email')")
      .form-row-field
        BFormInput(
          type="email"
          name="email"
          v-model="account.email"
          :placeholder="$t('account_management.create_account.placeholders.email')"
          :state="getFieldState('email')"
        )
        BFormInvalidFeedback(v-if="$v.account.email.$error") {{ $t("validations.invalid_email") }}

    .form-row(
      :data-cy="$t('account_management.create_account.fields.phone_number')"
    )
      .form-row-label
        FormFieldLabel(:title="$t('account_management.create_account.fields.phone_number')")
      .form-row-field
        BFormInput(
          type="text"
          name="phone-number"
          v-model="account.phone_number"
          :placeholder="$t('account_management.create_account.placeholders.phone_number')"
          :state="getFieldState('phone_number')"
        )
        BFormInvalidFeedback(v-if="$v.account.phone_number.$error") {{ $t("validations.invalid_phone_number") }}

    .form-row.justify-content-end
      AppSaveButton.float-right.m-t-20(
        title="actions.confirm"
        @save="updateAccount"
      )
</template>

<script>
  // mixins
  import withPasswordValidationErrors from "@/mixins/withPasswordValidationErrors"
  import withValidations from "@/mixins/withValidations"

  // misc
  import { api } from "@/config"
  import { isEmpty } from "lodash-es"
  import { EMAIL_REGEXP, PHONE_NUMBER_REGEXP } from "@/config/constants"

  const validationsMixin = withValidations(({ required, minLength }) => ({
    account: {
      first_name: {
        required,
        minLength: minLength(1)
      },
      last_name: {
        required,
        minLength: minLength(1)
      },
      password: {
        minLength: minLength(8),
        sameAs: (value, self) => value === self.password_confirmation
      },
      password_confirmation: {
        minLength: minLength(8),
        sameAs: (value, self) => value === self.password
      },
      email: {
        emailCheck: value => isEmpty(value) || EMAIL_REGEXP.test(value)
      },
      phone_number: {
        phoneCheck: value => isEmpty(value) || PHONE_NUMBER_REGEXP.test(value)
      }
    }
  }))

  export default {
    props: {
      accountData: {
        type: Object,
        required: true
      }
    },

    components: {
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppInputPassword: () => import("@/components/elements/AppInputPassword"),
      AppSaveButton: () => import("@/components/elements/AppButton/Save"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [withPasswordValidationErrors, validationsMixin],

    data() {
      return {
        account: { ...this.accountData },
        loading: false,
        isAccountUpdatedAlertShown: false
      }
    },

    methods: {
      getFieldState(fieldName) {
        return this.$v.account[fieldName].$error ? false : null
      },

      updateAccount() {
        this.loading = true
        this.validateAttributes()

        if (!this.isValidAccount) {
          this.loading = false
          return
        }

        api
          .put("/current_user", { user: this.userParams() })
          .finally(() => (this.loading = false))
          .then(() => {
            this.isAccountUpdatedAlertShown = true
            this.cleanPasswordFields()
            this.$store.dispatch("FETCH_CURRENT_USER")
          })
      },

      userParams() {
        const { password, password_confirmation, ...accountData } = this.account

        if (password === "" || password_confirmation === "") {
          return accountData
        } else {
          return this.account
        }
      },

      cleanPasswordFields() {
        this.account.password = ""
        this.account.password_confirmation = ""
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .edit-profile
    .form
      margin: 60px 0 0 25px

      &-row
        align-items: flex-start
        display: flex
        margin: 0 0 20px 0
        width: 100%

        &-label
          padding-top: 6px
          width: 230px

        &-field
          padding: 0 6px
          width: 234px

          .password-errors
            color: $default-red
            font-size: 80%
            margin-top: 0.25rem

          input
            border-color: $default-purple

            &.is-invalid
              border-color: $default-red
</style>
